<script setup lang="ts">
import { subject } from '@casl/ability';
import type { Spell } from '@respell/database';
import ConfirmModal from '~/components/modals/ConfirmModal.vue';
import ShareModal from '~/components/modals/ShareModal.vue';
import { useAppAbility } from '~/composables/useAppAbility';

const { can } = useAppAbility();
const props = defineProps<{
  spell: Spell;
  isTriggered?: boolean;
}>();

const spellStore = useSpellsStore();
const modal = useModal();
const spellId = useRouteParams('spellId');
const teamId = useRouteParams('teamId');

const inSpellPage = computed(() => !!spellId.value);

const moreOptions = computed(() => {
  const baseOptions = [];

  if (can('edit', subject('Spell', props.spell))) {
    baseOptions.push({
      name: 'Settings',
      icon: 'i-ph-gear',
      color: 'black',
      action: () =>
        navigateTo(`/spell/${props.spell.id}/settings`, {
          replace: true,
        }),
    });
    if (props.spell.templateMetadata?.preview) {
      baseOptions.push({
        name: 'Template',
        icon: 'i-ph-eye',
        color: 'black',
        action: () =>
          navigateTo(`/spell/${props.spell.id}/preview`, {
            replace: true,
          }),
      });
    }
  }

  baseOptions.push({
    name: 'Duplicate',
    icon: 'i-ph-copy',
    color: 'black',
    action: () =>
      spellStore.duplicateSpell(props.spell.id, teamId.value as string),
  });

  if (!inSpellPage.value) {
    baseOptions.push({
      name: 'Share',
      icon: 'i-ph-user-plus',
      color: 'black',
      action: () => modal.open(ShareModal, { spellId: props.spell.id }),
    });
    if (!props.isTriggered) {
      baseOptions.splice(2, 0, {
        name: 'Insights',
        icon: 'i-ph-chart-line',
        color: 'black',
        action: () => navigateTo(`/spell/${props.spell.id}/history`),
      });
    }
  }

  if (can('delete', subject('Spell', props.spell))) {
    baseOptions.push({
      name: 'Delete',
      icon: 'i-ph-trash-simple',
      color: 'rose',
      action: handleDelete,
    });
  }

  return baseOptions;
});

async function handleDelete() {
  modal.open(ConfirmModal, {
    type: 'spell',
    action: 'delete',
    message: 'Are you sure you want to delete this spell?',
    isDangerous: true,
    async onConfirm() {
      await spellStore.deleteSpell(props.spell.id);
      modal.close();
      if (inSpellPage.value) await navigateTo('/');
    },
  });
}
</script>
<template>
  <div class="contents" @click.stop>
    <USelectMenu :options="moreOptions">
      <UTooltip text="More options">
        <slot name="button" />
      </UTooltip>
      <template #option="{ option }">
        <UButton
          :icon="option.icon"
          size="md"
          :color="option.color"
          variant="ghost"
          :label="option.name"
          :trailing="false"
          @click="option.action"
        />
      </template>
    </USelectMenu>
  </div>
</template>
